@media only screen and (min-width: 1024px) {
  .alex-button:hover,
  .alex-button:active,
  .alex-button:focus {
    background-color: #2C3BAA !important;
    color: white !important;
  }

  .alex-blue-button:hover,
  .alex-blue-button:focus,
  .alex-blue-button:active {
    border-color: #2c3baa !important;
    background-color: #2c3baa !important;
  }

  .alex-ghost-button:hover,
  .alex-ghost-button:focus,
  .alex-ghost-button:active {
    background-color: #3e4db9 !important;
    color: white !important;
  }
}

.alex-button {
  width: 100%;
  padding: 15px;
  font-weight: bold;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 40px;
}

.alex-row-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alex-shrink-button {
  width: auto;
}

.alex-blue-button {
  color: white;
  border-color: #3e4db9;
  background-color: #3e4db9;
}

.alex-blue-button:active,
.alex-blue-button:focus,
.alex-blue-button:hover {
  outline: none !important;
  box-shadow: none !important;
  background-color: #2c3baa !important;
  color: white !important;
}

.alex-ghost-button {
  color: #3e4db9 !important;
  border-color: #3e4db9 !important;
  background-color: white !important;
}

.alex-ghost-button:active,
.alex-ghost-button:focus {
  background-color: #3e4db9 !important;
  color: white !important;
}

.alex-ghost-button-selected {
  background-color: #3e4db9 !important;
  color: white !important;
}

.alex-white-button,
.alex-white-button:hover,
.alex-white-button:focus,
.alex-white-button:active {
  color: #3e4db9 !important;
  border-color: white !important;
  background-color: white !important;
}

.alex-red-button,
.alex-red-button:hover,
.alex-red-button:focus,
.alex-red-button:active {
  color: white !important;
  border-color: #ff5e65 !important;
  background-color: #ff5e65 !important;
}

.alex-green-button,
.alex-green-button:hover,
.alex-green-button:focus,
.alex-green-button:active {
  color: white !important;
  border-color: #009888 !important;
  background-color: #009888 !important;
}

.alex-black-button,
.alex-black-button:hover,
.alex-black-button:focus,
.alex-black-button:active {
  color: white !important;
  border-color: #00161f !important;
  background-color: #00161f !important;
}
