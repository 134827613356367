@media only screen and (min-width: 1024px) {
  h1,
  h2,
  h3,
  h5,
  h6 {
    font-size: 48px !important;
    line-height: 54px !important;
  }

  h3 {
    font-size: 40px !important;
  }

  h4 {
    font-size: 24px !important;
  }

  h5 {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  p {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  button {
    font-size: 20px !important;
  }

  .h-100 {
    min-height: 100vh !important;
  }

  .alex-footer-text {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .alex-icon {
    height: calc(60px * 1.25) !important;
    width: calc(50px * 1.25) !important;
  }

  .vertical-center {
    padding-bottom: 30px;
  }
}

.alex-icon {
  margin-bottom: 10px;
  height: 60px;
  width: 50px;
}

@font-face {
  font-family: 'NeuzeitGro-Reg';
  src: url('./assets/fonts/NeuzeitGro-Reg.ttf') format('truetype');
}

@font-face {
  font-family: 'NeuzeitGro-Bol';
  src: url('./assets/fonts/NeuzeitGro-Bol.ttf') format('truetype');
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-image: url('./assets/images/alex-background.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.h-100 {
  height: 100%;
}

p {
  margin: 0;
  font-size: 18px;
  line-height: 27px;
  font-family: 'NeuzeitGro-Reg';
}

h1 {
  font-family: 'NeuzeitGro-Bol';
  line-height: 42px;
  font-size: 40px;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: 'NeuzeitGro-Bol';
}

button {
  font-weight: normal !important;
  font-family: 'NeuzeitGro-Bol';
  font-size: 20px;
}

.subheader {
  color: #3e4db9;
}

.vertical-center {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.alex-main-container {
  margin: auto;
  width: 100%;
}

.item-margin {
  margin-bottom: 8px;
}

.alex-footer {
  padding-left: 10px;
  padding-right: 10px;
}

.alex-section {
  margin-top: 15px;
  margin-bottom: 25px;
}

.alex-subheader {
  color: #3e4db9;
}

.alex-card {
  border-radius: 24px;
  background-color: #3e4db9;
}

.alex-card-body {
  color: white;
  padding: 34px 30px 34px 30px;
}

.insights-image {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  max-width: 100%;
  height: auto;
}

.small-text {
  font-size: 15px !important;
  line-height: 21px !important;
  margin-top: -15px;
}

.alex-transition-in {
  animation: fadein 250ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100);
  }
  to {
    opacity: 1;
    transform: translateY(100);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes moveIn {
  from {
    transform: translateY(30px);
  }
  to {
    transform: translateY(0px);
  }
}

@keyframes moveOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-30px);
  }
}

.alex-leave {
  animation: fadeOut 250ms, moveOut 250ms;
}

.alex-enter {
  animation: fadeIn 250ms, moveIn 250ms;
}

.alex-row {
  padding-left: 10px;
  padding-right: 10px;
}

.alex-column {
  max-width: 600px;
}

.alex-footer-text {
  color: #a7acc9;
  font-size: 12px;
  line-height: 18.5px;
}

.alex-compliance-text {
  margin-top: 10px;
}

.alex-cookie-banner {
  z-index: 999;
  padding: 20px;
  align-items: center;
  justify-content: center;
  background-color: #121947;
  position: absolute;
  display: flex;
  color: white;
  bottom: 0;
  right: 0;
  left: 0;
}

.alex-cookie-banner p {
  font-size: 14px !important;
  line-height: 20px !important;
}

.alex-text-link {
  text-decoration: underline;
  color: white;
}

.alex-rotate {
  transform: rotate(180deg);
}

.alex-hint {
  font-size: 18px !important;
  margin-top: -20px !important;
}
